import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../styles/login.scss';
import { useAuth } from "../user/authContext";

export const ForgotParssword = () => {
  const {t} = useTranslation("global")
  const [password, setPassword] = useState('')

  const auth = useAuth();

  const handleOnChange = (props: string) => {
    return (e: any) => setPassword(e.target.value)
  }

  const resetPassword = (email: any) => {
    return sendPasswordResetEmail(auth, email).then((a) => {
      alert("Password reset email sent")
    })
  }

  const handleOnSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault(); 

    resetPassword(password).then(() => {
      alert("Mail enviado")
    })
  }


  return <div className="forgot-password">
    <h3>Introduce tu correo electrónico</h3>
    <form action="" className='log-form-style' onSubmit={(e) => handleOnSubmit(e)}>
      <input 
        type="mail" 
        name="mail"
        className='cpanel-input'
        placeholder='Email'
        onChange={handleOnChange(password)} 
      />
      <button type='submit' className='btn-login'>{t("login.singInBtn")}</button>
    </form>  
  </div> 
}
