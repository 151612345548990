import '../styles/contact.scss';
import { useTranslation } from 'react-i18next';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';

export const ContactUs = () => {
  const {t} = useTranslation("global");

  return <div className='contact-us'>
    <div id='contact-us'></div>
    <div className='contact-us-container'>
      <div className='contact-us-text'>
        <h2>{t("contact.title")}</h2>
        <form action="contacto.php" method="post" className='contact-form'>
          <div className='input-box'>
            <input type="text" name="nombre" id="nombre" placeholder={t("contact.name")} required/>
          </div>
          <div className='input-box'>
            <input type="email" name="email" id="email" placeholder={t("contact.email")} required/>
          </div>
          <div className='input-box'>
            <textarea name="message" id="message" placeholder={t("contact.message")} required></textarea>
          </div>
          <div>
            <button className='btn' type='submit'>{t("contact.btn")}</button>
          </div>
        </form>
      </div>
      <div className='social'>
          <a href="https://www.instagram.com/myartvisit/" target='_blank' className='icon'><img src={instagram} alt="" /></a>
          <a href="https://www.facebook.com/My-Art-Visit-100534918342028" target='_blank' className='icon'><img src={facebook} alt="" /></a>
        </div>
    </div>
  </div>
}