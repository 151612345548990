import '../../styles/aboutUs.scss';
import mav from '../../assets/about.jpg';
import { useTranslation } from 'react-i18next';

export const AboutUs = () =>{
  const {t} = useTranslation("global");

  return <div className='about-us'>
    <div id='about-us'></div>
    <div className='about-us-container'>
      <div className='about-us-text'>
        <h2>{t("aboutMav.title")}</h2>
        <p>{t("aboutMav.text")}</p>
        <a href='https://myartvisitblog.wordpress.com/2022/05/19/hola-soy-gaby-marcuzzi/' target="_blank">{t("aboutMav.link")} →</a>
      </div>
      <div className='about-us-img'>
        <img src={mav} alt="" />
      </div>
    </div>
  </div>
}