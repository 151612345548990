import arrow from '../assets/back.svg';
import { useHistory } from 'react-router';

export const GoBack = (props: any) =>{
  const history = useHistory()
  const goBack = () => {
    history.goBack()
  }

  return <button className={props.style} onClick={goBack}><img src={arrow} alt=''></img><p>{props.text}</p></button>
}