import { Switch, Route, Redirect } from 'react-router-dom';
import { Expositions } from '../pages/expositions';
import { Home } from '../pages/home';
import { ExpositionPage } from '../pages/expositonPage';
import { Login } from '../pages/login';
import { Tematics } from '../pages/tematics';
import { ForgotParssword } from './login/forgotPassword';
import { useAuth } from './user/authContext';
import { IsAdmin } from '../backoffice/isAdmin';
import { AddTematic } from '../backoffice/addTematic';
import { TematicForm } from '../backoffice/forms/tematicForm';
import { EditTematic } from '../backoffice/editForms/editTematic';
import { MyExpositions } from '../backoffice/myExpositions';
import { ExpositionForm } from '../backoffice/forms/expositionForm';
import { ArtForm } from '../backoffice/forms/artForm';
import { MyWorksOfArt } from '../backoffice/myWorksOfArt';
import { Preview } from '../backoffice/preview';
import { EditExposition } from '../backoffice/editForms/editExposition';
import { EditObra } from '../backoffice/editForms/editObra';

export function Routes() {
  const user = useAuth();
  return (
    <Switch>
      <Route path='/' exact component={Home}></Route>
      <Route path='/museos' component={Tematics}></Route>
      <Route path='/recorridos/:tematicId' component={Expositions}></Route>
      <Route path='/muestra/:expositionId' component={ExpositionPage}></Route>
      <Route
        path='/log-in'
        render={() => {
          return user.isLoggedIn ? <Redirect to='/' /> : <Login />;
        }}
      ></Route>
      <Route path='/forgot-password' component={ForgotParssword}></Route>

      {/*Backoffice*/}
      <Route path='/cpanel/tematics' component={IsAdmin(AddTematic)}></Route>
      <Route path='/cpanel/add-tematic-form' component={IsAdmin(TematicForm)}></Route>
      <Route path='/cpanel/edit-tematic/:tematicId' component={IsAdmin(EditTematic)}></Route>
      <Route path='/cpanel/expositions' component={IsAdmin(MyExpositions)}></Route>
      <Route path='/cpanel/edit-exposition/:expositionId' component={IsAdmin(EditExposition)}></Route>
      <Route path='/cpanel/add-exposition-form' component={IsAdmin(ExpositionForm)}></Route>
      <Route path='/cpanel/art-form' component={IsAdmin(ArtForm)}></Route>
      <Route path='/cpanel/my-works-of-art/:expositionId' component={IsAdmin(MyWorksOfArt)}></Route>
      <Route path='/cpanel/edit-obra/:obraId' component={IsAdmin(EditObra)}></Route>
      <Route path='/cpanel/preview/:expositionId' component={IsAdmin(Preview)}></Route>
      <Route path='/cpanel'>
        <Redirect to='/cpanel/tematics' />
      </Route>
      <Redirect to='/' />
    </Switch>
  );
}
