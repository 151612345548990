import '../styles/dashboard.scss';
import { CardDashboard } from './cardDashboard';
import { HeaderBackOffice } from './headerBackOffice';
import { NavBar } from './navbar';
import { useState, useEffect } from 'react';
import { Spinner } from '../components/spinner';
import { useParams } from 'react-router-dom';
import { Obra, useObra } from '../hooks/useObra';
import { Exposition, useExposition } from '../hooks/useExposition';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ObraCard } from './obraCard';

export const MyWorksOfArt = () => { 
  const [isLoading, setIsLoading] = useState(true)
  
  const { expositionId } = useParams<{expositionId: string}>()
  const obra = useObra()
  const exposition = useExposition()
  
  const [ obrasList, setObrasList ] = useState<Obra[]>([]) 
  const [ expositionData, setExpositionData ] = useState<Exposition>()
  

  useEffect(() => {
    obra.list(expositionId).then((l) => {
      setObrasList(l.data)
    })
    .then(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    exposition.get(expositionId).then((l) => {
      setExpositionData(l.data as any)
    })
  }, [])


  const deleteObra = (id: string) => {
    setIsLoading(true);
    obra.remove(id).then(() =>  
      obra.list(expositionId)
        .then((l) => {
          setObrasList(l.data)
          setIsLoading(false);
        }))
      .then(response => {
        toast.success('Obra eliminada exitosamente')
    })
  } 

  const completeList = obrasList.map((l) => {
    return <ObraCard 
      obraId={l.id}
      title={l.name.es} 
      deleteBtn={() => deleteObra(l.id as string)}
      numberOrden={l.order}
      /> 
  })

  const emptyList = <p className='empty-list-msg'>Este recorrido no contiene obras</p>

  return <div className="c-panel-dashboard">
    <HeaderBackOffice />
    <div className="dashboard-container">
      <NavBar />
      <div className='general-dashboard-container'>
      { isLoading ? <Spinner /> :
      <div className='card-dashboard-container'>
        <p className='obra-list-title-page'>{expositionData?.name.es} / obras</p>
        <div className='card-dashboard-list'> 
          {!obrasList.length ? emptyList : completeList} 
        </div>
      </div>
        }
        <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    </div>
    </div>
  </div>
}
