import { HeaderBackOffice } from "../headerBackOffice"
import '../../styles/dashboard.scss';
import { GoBack } from "../../components/goBack";
import { useState, useEffect } from "react";
import { useExposition } from "../../hooks/useExposition";
import { useHistory } from "react-router-dom";
import { Tematic, useTematic } from "../../hooks/useTematic";
import { Spinner } from "../../components/spinner";

export const ExpositionForm = () => {
  const [ form, setForm ] = useState({
    image: [],
    tematicId: '',
    titleEs: '',
    titleEn: '',
    descriptionEs: '',
    descriptionEn: '',
    resumeEs: '',
    resumeEn: '',
    museum: '',
    adress: '',
  })

  const exposition = useExposition();
  const history = useHistory();
  const tematic = useTematic();

  const [disable, setDisable] = useState(false) 
  
  const [error, setError] = useState(false)
  const [TematicOptions, setTematicOptions ] = useState<Tematic[]>([])

  const handleOnChange = (prop: string) => {
    return (e: any) => setForm({...form, [prop]: e.target.value})
  }

  const handleImage = (props: any) => {
    return (e: any) => setForm({...form, [props]:e.currentTarget.files})
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation();
    e.preventDefault();
    
    if(!form.image.length || form.titleEs === '' || form.titleEn === '' || form.descriptionEn === '' || form.descriptionEs === '' || form.museum === '' || form.adress === '') {
      return setError(true);
    }
    setError(false);

    if(disable) {
      return;
    }
    setDisable(true)

    exposition.create({
      files: form.image,
      name: {es: form.titleEs ,en: form.titleEn},
      description: {es: form.descriptionEs, en:form.descriptionEn},
      resume: {es: form.resumeEs, en: form.resumeEn},
      tematicId: form.tematicId,
      address: form.adress, 
      museum: form.museum
    }).then((r) => {
      if(!r.isError){
        history.push('/cpanel/expositions');
      }
    })
  }

  useEffect(() => {
    tematic.list().then((l) => {
      const tematicOptions = l.data;
      setTematicOptions(tematicOptions)
    })
  }, [])

  return <div className="cpanel-form-page">
  <HeaderBackOffice />
  <div className="cpanel-sub-header">
    <div>
      <GoBack />
      <h3>Crear recorrido</h3>
    </div>
  </div>
  <div className="cpanel-form-container">
    <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e)}>
    <label className="cpanel-label">Seleccionar imagen del recorrido</label>
      <input 
        type="file" 
        name="image" 
        className='cpanel-file'
        onChange={handleImage('image')}
      />
      <label className="cpanel-label">Temática</label>
      <select className="cpanel-select" onChange={handleOnChange('tematicId')} >
        <option value="" hidden>Seleccionar Temática</option>
        {TematicOptions.map((t) => {
          return <option value={t.id}>{t.name.es}</option>
        })}
      </select>
      <br /><hr /><br />
      <label className="cpanel-label">Título del recorrido (Español)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input" 
        onChange={handleOnChange('titleEs')}
        
      />
       <label className="cpanel-label">Descripción breve (Español)</label>
      <textarea 
        name="description" 
        id="" 
        className="cpanel-textarea"
        onChange={handleOnChange('descriptionEs')}
        >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Título del recorrido (Inglés)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input" 
        onChange={handleOnChange('titleEn')}
      />
      <label className="cpanel-label">Descripción breve (Inglés)</label>
      <textarea 
        name="description" 
        id="" 
        className="cpanel-textarea"
        onChange={handleOnChange('descriptionEn')}

        ></textarea>
        <br /><hr /><br />
      <label className="cpanel-label">Museo</label>
      <input 
        type="text" 
        name="museum" 
        className="cpanel-input" 
        onChange={handleOnChange('museum')}
        
      />
       <label className="cpanel-label">Dirección del museo</label>
      <input 
        type="text" 
        name="direction" 
        className="cpanel-input" 
        onChange={handleOnChange('adress')}
        
      />
      <br /><hr /><br />
      <label className="cpanel-label">Resumen (Español)</label>
      <textarea 
        name="resume"  
        className="cpanel-textarea"
        onChange={handleOnChange('resumeEs')}

        ></textarea>
        <label className="cpanel-label">Resumen (Inglés)</label>
      <textarea 
        name="resume"  
        className="cpanel-textarea"
        onChange={handleOnChange('resumeEn')}

        ></textarea>
      <button type='submit' className='btn-dashboard' disabled={disable}>
      {disable ? <Spinner /> : 'Crear Recorrido'}  
        
        </button>
    </form>
    {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
  </div>
</div>
}