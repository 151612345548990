import { useState } from "react";
import '../../styles/login.scss';
import { useTranslation } from 'react-i18next';
import { useAuth, useLogin } from "../user/authContext";
import { AlertForm } from "../alertForm";
import { useHistory } from "react-router-dom";
import { Spinner } from "../spinner";

export const LogIn = () => {
  const {t} = useTranslation("global");
  const [alert, setAlert] = useState({});
  const [form, setForm] = useState({
    email: '',
    password: ''
  })
  
  const user = useAuth();
  const history = useHistory();
  const [disable, setDisable] = useState(false) 

  const handleOnChange = (props: string) => {
    return (e: any) => setForm({...form, [props]: e.target.value})
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation();
    e.preventDefault();

    if(disable) {
      return;
    }
    setDisable(true)

    doLogin(form).then((response) => {
      if(response.isError) {
        if (form.email !== user.email || form.password !== user.password ) {
          setAlert({msg: '*Email y/o contraseña incorrectas', error: true})
         } else {
           history.goBack()
         }
      }
    }).then(() => 
      setDisable(false)
    )
  }

  const doLogin = useLogin();   

  return <form action="" className='log-form-style' onSubmit={(e) => handleOnSubmit(e)}>
    <AlertForm props={alert}/>
    <input 
      type="mail" 
      name="mail"
      className='cpanel-input'
      placeholder='Email'
      onChange={handleOnChange('email')} 
    />
    <input 
      type="password" 
      name="password"
      className='cpanel-input'
      placeholder={t("login.password")}
      onChange={handleOnChange('password')}   
    />
    <button type='submit' className='btn-login' disabled={disable}>
      {disable ? <Spinner /> : t("login.singInBtn")}
    </button>
  </form>  
}