import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref, StorageReference, uploadBytes } from 'firebase/storage';
import { db, storage } from '../firebase/firebaseConfig';
import { useCache } from './useCache';

const FIREBASE_TEMATIC_COLLECTION = 'tematics';

export type Tematic = {
  id?: string;
  name: Record<string, string>;
  description: Record<string, string>;
  files?: File[] | string[];
};

export const useTematic = (defaultTematicId: string = '') => {
  const cache = useCache('TEMATICS');

  const create = async (tematic: Tematic) => {
    const fileRefs = [];
    const newTematic = await addDoc(collection(db, FIREBASE_TEMATIC_COLLECTION), {
      name: tematic.name,
      description: tematic.description,
    });
    if (tematic.files) {
      const rollbackTematic = () => deleteDoc(newTematic);
      try {
        for (const file of tematic.files as File[]) {
          const storageRef = ref(storage, `${newTematic.id}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        await rollbackTematic();
        return { isError: true, error: e };
      }
    }
    const createdTematic = await getDoc(newTematic);
    const data = {
      ...createdTematic.data(),
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const get = async (tematicId = defaultTematicId) => {
    if (!tematicId) {
      return { isError: true, error: 'No tematic Specified' };
    }
    const cachedItems = cache.get();
    const fetchedItem = cachedItems?.find((i: Tematic) => i.id === tematicId);
    if (fetchedItem) {
      const storageRef = ref(storage, tematicId);
      let files, fileUrls;
      try {
        files = await listAll(storageRef);
        const filePromises = [];
        for (let file of files.items) {
          filePromises.push(getDownloadURL(file));
        }
        fileUrls = await Promise.all(filePromises);
      } catch {}
      return { isError: false, data: { ...fetchedItem, files, fileUrls } };
    }
    const docRef = doc(db, FIREBASE_TEMATIC_COLLECTION, tematicId);
    const tematic = await getDoc(docRef);
    if (!tematic) {
      return { isError: true, error: 'Not found' };
    }
    const storageRef = ref(storage, tematicId);
    let files,
      fileUrls = [];
    try {
      files = await listAll(storageRef);
      for (let fileRef of files.items) {
        fileUrls.push(await getDownloadURL(fileRef));
      }
    } catch {}
    return {
      isError: false,
      data: {
        id: tematicId,
        ...tematic.data(),
        files,
        fileUrls,
      } as Tematic & { fileUrls: string[] },
    };
  };

  const remove = async (tematicId = defaultTematicId) => {
    if (!tematicId) {
      return { isError: true, error: 'No tematic Specified' };
    }
    const docRef = doc(db, FIREBASE_TEMATIC_COLLECTION, tematicId);
    try {
      await deleteDoc(docRef);
      const filesRef = ref(storage, tematicId);
      const files = await listAll(filesRef);
      await Promise.all(
        files.items.map((i) => {
          return deleteObject(i);
        })
      );
      cache.clear();
      return { isError: false, data: { deleted: tematicId } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const update = async (tematic: Tematic) => {
    const tematicId = tematic.id ?? defaultTematicId;
    if (!tematicId) {
      return { isError: true, error: 'No tematic specified' };
    }
    const fileRefs = [];
    await setDoc(doc(db, FIREBASE_TEMATIC_COLLECTION, tematicId), {
      name: tematic.name,
      description: tematic.description,
    });
    if (tematic.files && tematic.files.length) {
      try {
        for (const file of tematic.files as File[]) {
          const storageRef = ref(storage, `${tematicId}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        return { isError: true, error: e };
      }
    }
    const data = {
      ...tematic,
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const removeFile = async (fileRef: StorageReference) => {
    try {
      await deleteObject(fileRef);
      cache.clear();
      return { isError: false, data: { deleted: true } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const list = async (fromServer = false) => {
    const cachedItems = cache.get();
    if (cachedItems && !fromServer) {
      return { isError: false, data: cachedItems };
    }
    const docs = await getDocs(collection(db, FIREBASE_TEMATIC_COLLECTION));
    const tematics: (Tematic & { fileUrls: string[] })[] = [];
    const allLoadedPromises = [];
    for (let doc of docs.docs) {
      const storageRef = ref(storage, doc.id);
      const files = await listAll(storageRef);
      const filePromises = [];
      for (let file of files.items) {
        filePromises.push(getDownloadURL(file));
      }
      const data = doc.data() as Tematic;
      const loadedPromise = Promise.all(filePromises).then((fileUrls) => {
        tematics.push({ id: doc.id, ...data, files: files as any, fileUrls });
      });
      allLoadedPromises.push(loadedPromise);
    }
    await Promise.all(allLoadedPromises);
    cache.set(tematics);
    return { isError: false, data: tematics };
  };

  return { list, create, get, remove, update, removeFile };
};
