import '../../styles/comments.scss';
import { DeleteBtn } from '../deleteBtn';
import { useAuth } from '../user/authContext';

export const CommentCard = (props: any) => {
  const user = useAuth();
  return (
    <div className='comment-box'>
      <div className='comment-user-header'>
        <div className='comment-user-name'>
          <p>{props.name}</p>
          <p>{props.surname}</p>
        </div>
        {props.ownerId === user.uid ? <DeleteBtn onClick={props.deleteComment} /> : <></>}
      </div>
      <div className='comment-text'>
        <p>{props.comment}</p>
      </div>
    </div>
  );
};
