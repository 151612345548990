import { Link } from 'react-router-dom';
import { DeleteBtn } from '../components/deleteBtn';
import '../styles/cards.scss';

export const ObraCard = (props: any) => {
  return (
    <div className='card-dashboard-box'>
      <div className='card-title-dashboard'>{props.title} - ({props.numberOrden})</div>
      <div className='edit-delete-btn'>
        <Link to={`/cpanel/preview/${props.previewId}`} className='edit e-color'>
          {props.previewLink}
        </Link>
        <Link to={`/cpanel/my-works-of-art/${props.expositionId}`} className='edit e-color'>
          {props.obras}
        </Link>
        <Link to={`/cpanel/edit-obra/${props.obraId}`} className='edit'>
          Editar
        </Link>
        <DeleteBtn onClick={props.deleteBtn} />
      </div>
    </div>
  );
};
