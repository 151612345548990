import { GoBack } from "../../components/goBack";
import { Spinner } from "../../components/spinner";
import { HeaderBackOffice } from "../headerBackOffice";
import { useObra } from "../../hooks/useObra";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Exposition, useExposition } from "../../hooks/useExposition";
import audio from '../../assets/audioIcon.svg';
import map from '../../assets/mapIcon.svg';

export const EditObra = () => {
  const obra = useObra()
  const { obraId } = useParams<{obraId: string}>()

  useEffect(() => {
    obra.get(obraId).then(async (response) => {
      setForm({
        image: response.data?.fileUrls?.mainImg,
        map: response.data?.fileUrls?.map,
        audioEs: response.data?.fileUrls?.[`audio-es`],
        audioEn: response.data?.fileUrls?.[`audio-en`],
        expositionId: response.data?.expositionId,
        titleEs: response.data?.name.es,
        titleEn: response.data?.name.en,
        artDescriptionEs: response.data?.description.es,
        artDescriptionEn: response.data?.description.en,
        artist: response.data?.author,
        order: response.data?.order
      })
    })
  }, [])

  const [form, setForm] = useState<any>({})
  const [ error, setError] = useState(false)
  const [disable, setDisable] = useState(false)

  const deleteImg = () => obra.removeFile(form.image.ref).then(() => {
    setForm({ ...form, image: null})
  });

  const deleteAudioEs = () => obra.removeFile(form.audioEs).then(() => {
    setForm({...form, audioEs: null})
  });

  const deleteAudioEn = () => obra.removeFile(form.audioEn).then(() => {
    setForm({...form, audioEn: null})
  });

  const deleteMap = () => obra.removeFile(form.map).then(() => {
    setForm({...form, map: null})
  });

  const handleOnChange = (props: any) => {
    return (e: any) => setForm({...form, [props]:e.target.value})
  }

  const handleFile = (fileName: string) => {
    return (e: any) => {
      const realFile = e.currentTarget.files[0];
      const [, extension] = realFile.name.split('.');
      const file = new File([realFile], `${fileName}.${extension}`, { type: realFile.type });
      setForm({ ...form, files: { ...(form.files ?? {}), [fileName]: file }})
    }
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation()
    e.preventDefault();

    if(form.tematicEs === '' || form.tematicEn === '' || form.descriptionEs === '' || form.descriptionEn === '') {
      return setError(true);
    }
    setError(false)

    if(disable){
      return;
    }
    setDisable(true);

    obra.update({
      id: obraId,
      files: form.files,
      name: {es: form.titleEs, en: form.titleEn},
      description: {es: form.artDescriptionEs, en: form.artDescriptionEn},
      expositionId: form.expositionId,
      author: form.artist,
      order: form.order
    }).then(() => {
      toast.success('Modificación existosa')
      setDisable(false)
    })
  }

  const exposition = useExposition()
  const [expositionItem, setExpositionItem] = useState<Exposition[]>([])
  useEffect(() => {
    exposition.list().then((l) => {
      const expositionId = l.data
      setExpositionItem(expositionId)
    })
  }, [])

  return <div className="cpanel-form-page">
  <HeaderBackOffice />
  <div className="cpanel-sub-header">
    <div>
      <GoBack />
      <h3>Editar obra</h3>
    </div>
  </div>
  <div className="cpanel-form-container">
    <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e)}>
    {form.image === null ? <>
        <label className="cpanel-label">Seleccionar nueva imagen de temática</label>
        <input 
          type="file" 
          name="image"
          accept="image/*"
          className='cpanel-file'
          onChange={handleFile('mainImg')} 
        />
        
      </> 
        : 
      <>
       <img src={form.image} style={{height: "100px", width: "100px"}} alt='' />
        <div onClick={() => deleteImg()}>Borrar</div>
      </>
      }
      <label className="cpanel-label">Recorrido</label>
      <select className="cpanel-select" onChange={handleOnChange('expositionId')} value={form.expositionId}>
        <option value="" hidden>Seleccionar recorrido</option>
        {expositionItem.map((ex) => {
        return <option value={ex.id}>{ex.name.es}</option> 
        })}
      </select>
      <br /><hr /><br />
      <label className="cpanel-label">Título de la obra (Español)</label>
      <input 
        type="text" 
        name="titleEs" 
        className="cpanel-input"
        value={form.titleEs}
        onChange={handleOnChange('titleEs')} 
      />
      <label className="cpanel-label">Descripción de la obra (Español)</label>
      <textarea 
        name="artDescription" 
        className="cpanel-textarea"
        value={form.artDescriptionEs}
        onChange={handleOnChange('artDescriptionEs')} 
      >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Título de la obra (Inglés)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input"
        value={form.titleEn}
        onChange={handleOnChange('titleEn')} 
      />
      <label className="cpanel-label">Descripción de la obra (Inglés)</label>
      <textarea 
        name="artDescription" 
        className="cpanel-textarea"
        value={form.artDescriptionEn}
        onChange={handleOnChange('artDescriptionEn')} 
      >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Nombre del artista</label>
      <input 
        type="text" 
        name="artist" 
        className="cpanel-input"
        value={form.artist}
        onChange={handleOnChange('artist')} 
      />
      {form.map === null ? <>
        <label className="cpanel-label">Subir mapa</label>
        <input 
          type="file" 
          name="museum-location" 
          className='cpanel-file'
          accept="image/*"
          onChange={handleFile('map')}
        />
      </> : 
       <div className="audio-container">
        <div className="audio-box">
          <img src={map} alt="" style={{width: "28px", height: "32px"}} /> 
          Map
        </div>
        <div onClick={deleteMap}>Borrar</div>
       </div>
      }


      {form.audioEs === null ? <>
        <label className="cpanel-label">Subir audio (Español)</label>
        <input 
          type="file" 
          name="audio" 
          className='cpanel-file'
          // accept="audio/*"
          onChange={handleFile('audio-es')}
        />
        </> :
        <div className="audio-container">
          <div className="audio-box">
            <img src={audio} alt="" style={{width: "30px", height: "32px"}} /> 
            Audio ES
          </div>
        <div onClick={deleteAudioEs}>Borrar</div>
      </div>
      }


      {form.audioEn === null ? <>
        <label className="cpanel-label">Subir audio (Inglés)</label>
        <input 
          type="file" 
          name="audio" 
          className='cpanel-file'
          // accept="audio/*"
          onChange={handleFile('audio-en')}
        />
        </> :
        <div className="audio-container">
          <div className="audio-box">
            <img src={audio} alt="" style={{width: "30px", height: "32px"}} /> 
            Audio EN
          </div>
        <div onClick={deleteAudioEn}>Borrar</div>
      </div>
      }


       <label className="cpanel-label">Posición de lista</label>
      <input 
        type="number" 
        name="order" 
        value={form.order}
        className='cpanel-input'
        onChange={handleOnChange('order')}
      />
      <button type='submit' className='btn-dashboard' disabled={disable}>
        {disable ? <Spinner /> : 'modificar obra'}
      </button>
    </form>
    {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
  </div>
  <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
</div>
}
