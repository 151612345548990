import { useState, useEffect } from "react";
import { HeaderBackOffice } from "../headerBackOffice";
import '../../styles/dashboard.scss';
import { GoBack } from "../../components/goBack";
import { useObra } from '../../hooks/useObra'
import { useHistory } from "react-router-dom";
import { Exposition, useExposition } from "../../hooks/useExposition";
import { Spinner } from "../../components/spinner";

export const ArtForm = () =>{
 const [form, setForm] =useState({
   image: [],
   exposition: [],
   titleEs: '',
   titleEn: '',
   artDescriptionEs: '',
   artDescriptionEn: '',
   expositionId: '',
   artist: '',
   files: {},
   order: 0
 })
 const [error, setError] = useState(false)
 const [expositionId, setExpositionId] = useState<Exposition[]>([])
 const [disable, setDisable] = useState(false) 

 const history = useHistory()

 const obra = useObra()
 const exposition = useExposition()

 const handleOnChange = (props: string) => {
   return (e: any) => setForm({...form, [props]:e.target.value})
 }

  const handleFile = (fileName: string) => {
    return (e: any) => {
      const realFile = e.currentTarget.files[0];
      const [, extension] = realFile.name.split('.');
      const file = new File([realFile], `${fileName}.${extension}`, { type: realFile.type });
      setForm({ ...form, files: { ...(form.files ?? {}), [fileName]: file }})
    }
  }
 
 const handleOnSubmit = (e: any) =>{
   e.stopPropagation();
   e.preventDefault();

    if (!Object.values(form.files ?? {}).filter(f => !!f).length || form.titleEs === '' || form.titleEn === '' || !form.exposition || form.artist === '' || form.artDescriptionEs === '' || form.artDescriptionEn === '' ){
      return setError(true)
    }
    setError(false)

    if(disable) {
      return;
    }
    setDisable(true)

    obra.create({
      name: {es: form.titleEs, en: form.titleEn},
      description: {es: form.artDescriptionEs, en: form.artDescriptionEn},
      expositionId: form.expositionId,
      author: form.artist,
      files: form.files,
      order: form.order,
    })
    .then(() => {
      history.goBack()
    })
 }

 useEffect(() => {
   exposition.list().then((l) => {
     const expositionId = l.data
     setExpositionId(expositionId)
   })
 }, [])


  return <div className="cpanel-form-page">
  <HeaderBackOffice />
  <div className="cpanel-sub-header">
    <div>
      <GoBack />
      <h3>Agregar obra</h3>
    </div>
  </div>
  <div className="cpanel-form-container">
    <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e)}>
      <label className="cpanel-label">Seleccionar imagen de la obra</label>
      <input 
        type="file" 
        name="image" 
        className='cpanel-file'
        accept="image/*"
        onChange={handleFile('mainImg')}
      />
      <label className="cpanel-label">Recorrido</label>
      <select className="cpanel-select" onChange={handleOnChange('expositionId')}>
        <option value="" hidden>Seleccionar recorrido</option>
        {expositionId.map((ex) => {
        return <option value={ex.id}>{ex.name.es}</option> 
        })}
      </select>
      <br /><hr /><br />
      <label className="cpanel-label">Título de la obra (Español)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input"
        onChange={handleOnChange('titleEs')} 
      />
      <label className="cpanel-label">Descripción de la obra (Español)</label>
      <textarea 
        name="artDescription" 
        className="cpanel-textarea"
        onChange={handleOnChange('artDescriptionEs')} 
      >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Título de la obra (Inglés)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input"
        onChange={handleOnChange('titleEn')} 
      />
      <label className="cpanel-label">Descripción de la obra (Inglés)</label>
      <textarea 
        name="artDescription" 
        className="cpanel-textarea"
        onChange={handleOnChange('artDescriptionEn')} 
      >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Nombre del artista</label>
      <input 
        type="text" 
        name="artist" 
        className="cpanel-input"
        onChange={handleOnChange('artist')} 
      />
      {/*<label className="cpanel-label">Link del artista</label>
      <input 
        type="text" 
        name="link" 
        id="" 
        className="cpanel-input"
        onChange={handleOnChange('link')} 
      />*/}
      <label className="cpanel-label">Subir audio (Español)</label>
      <input 
        type="file" 
        name="name" 
        className='cpanel-file'
        onChange={handleFile('audio-es')}
      />
       <label className="cpanel-label">Subir audio (Inglés)</label>
      <input 
        type="file" 
        name="image" 
        className='cpanel-file'
        onChange={handleFile('audio-en')}
      />
      <label className="cpanel-label">Subir mapa</label>
      <input 
        type="file" 
        name="map" 
        accept="image/*"
        className='cpanel-file'
        onChange={handleFile('map')}
      />
       <label className="cpanel-label">Posición de lista</label>
      <input 
        type="number" 
        name="order" 
        className='cpanel-input'
        onChange={handleOnChange('order')}
        placeholder='0'
      />
      <button type='submit' className='btn-dashboard' disabled={disable}>
        {disable ? <Spinner /> : 'Agregar obra'}
      </button>
    </form>
    {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
  </div>
</div>
}