import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useEffect, useState, createContext, useContext, useCallback } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';

const UserContext = createContext<any>({});
export const useAuth = () => useContext(UserContext);

const logout = () => {
  signOut(auth);
};

/**
 * @example
 * <UserContextProvider
 *  onSuccess={() => function that does something on user log in}
 *  onError={() => function that does something on user error when logging in}
 * >
 *    // All the component tree that depend on user authentication to work
 * </UserContextProvider>
 * @param props
 */
export const UserContextProvider = (props: { onSuccess?: Function; onError?: Function; children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    onAuthStateChanged(
      auth,
      async function (user) {
        if (user) {
          const currentUserData = await getDoc(doc(db, 'users', user.uid));
          setUser({ ...user, ...currentUserData.data() });
          props.onSuccess?.();
        } else {
          setLoading(false);
          setUser({});
        }
      },
      (err) => props.onError?.(err)
    );
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoadingAuth: loading,
        ...user,
        logout,
        isLoggedIn: !!user.email,
        sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(auth, email),
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

type LoginResponse = { isError: boolean } & ({ isError: false; data: any } | { isError: true; error: any });

export const useLogin = () => {
  return useCallback(
    /**
     * @example
     * const doLogin = useLogin();
     * doLogin(form).then((response) => {
     *  if(response.isError) {
     *    // do something on error
     *  } else {
     *    // do something on successs
     *  }
     * });
     */
    (userCredentials: { email: string; password: string }): Promise<LoginResponse> => {
      return signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.password)
        .then((r) => ({ isError: false, data: r } as LoginResponse))
        .catch((e) => ({ isError: true, error: e }));
    },
    []
  );
};

export type UserProfile = {
  name: string;
  surname?: string;
  email: string;
  isAdmin?: boolean;
};

export type UserCredentials = {
  email: string;
  password: string;
};

const requiredFields = ['password', 'email', 'name'];

export const useRegister = () => {
  return useCallback(
    /**
     * @example
     * const register = useRegister();
     * register({ email: profile.email, password: password }, profile).then((r) => {
     *  if(response.isError) {
     *    // do something on error
     *  } else {
     *    // do something on successs
     *  }
     * });
     */
    async (userCredentials: UserCredentials, profile: UserProfile) => {
      if (requiredFields.every((f) => !!profile[f as keyof UserProfile])) {
        return {
          isError: true,
          error: { message: 'Insufficient data' },
        };
      }
      return createUserWithEmailAndPassword(auth, userCredentials.email, userCredentials.password)
        .then(async (res) => {
          const user = res.user;
          await setDoc(doc(db, 'users', user.uid), {
            isAdmin: false,
            ...profile,
            authProvider: 'local',
          });
          try {
            await sendEmailVerification(user);
          } catch (e) {
            return { isError: false, data: { id: user.uid, ...profile }, emailSent: false };
          }
          return { isError: false, data: { id: user.uid, ...profile }, emailSent: true };
        })
        .catch((e) => ({ isError: true, error: e }));
    },
    []
  );
};
