import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref, StorageReference, uploadBytes } from 'firebase/storage';
import { useAuth } from '../components/user/authContext';
import { db, storage } from '../firebase/firebaseConfig';
import { useCache } from './useCache';
import { useObra } from './useObra';

const FIREBASE_EXPOSITION_COLLECTION = 'exposition';

export type Exposition = {
  id?: string;
  name: Record<string, string>;
  tematicId: string;
  description: Record<string, string>;
  resume: Record<string, string>;
  highlight?: boolean;
  likes?: string[];
  address?: string;
  museum?: string;
  files?: File[] | string[];
  fileUrls?: string[];
  isLikedByCurrentUser?: boolean;
  state?: 'draft' | 'published';
};

export const useExposition = (defaultExpositionId: string = '') => {
  const cache = useCache('EXPOSITIONS');
  const { removeObrasFromExposition } = useObra();
  const auth = useAuth();

  const create = async (exposition: Exposition) => {
    if (!exposition.tematicId) {
      return { isError: true, error: 'No tematic specified' };
    }
    const fileRefs = [];
    const newExposition = await addDoc(collection(db, FIREBASE_EXPOSITION_COLLECTION), {
      tematicId: exposition.tematicId,
      name: exposition.name,
      description: exposition.description,
      resume: exposition.resume,
      address: exposition.address,
      museum: exposition.museum,
      highlight: exposition.highlight ?? false,
      likes: [],
      state: exposition.state ?? 'draft',
    });
    if (exposition.files) {
      const rollbackExposition = () => deleteDoc(newExposition);
      try {
        for (const file of exposition.files as File[]) {
          const storageRef = ref(storage, `${newExposition.id}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        await rollbackExposition();
        return { isError: true, error: e };
      }
    }
    const createdExposition = await getDoc(newExposition);
    const data = {
      ...createdExposition.data(),
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const get = async (expositionId = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition Specified' };
    }
    const cachedItems = cache.get();
    const requestedItem = cachedItems?.find((i: Exposition) => i.id === expositionId);
    if (requestedItem) {
      const storageRef = ref(storage, expositionId);
      let files, fileUrls;
      try {
        files = await listAll(storageRef);
        const filePromises = [];
        for (let file of files.items) {
          filePromises.push(getDownloadURL(file));
        }
        fileUrls = await Promise.all(filePromises);
      } catch {}
      return { isError: false, data: { ...requestedItem, files, fileUrls } };
    }
    const docRef = doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId);
    const exposition = await getDoc(docRef);
    if (!exposition) {
      return { isError: true, error: 'Not found' };
    }
    const data = exposition.data();
    const storageRef = ref(storage, expositionId);
    let files, fileUrls;
    try {
      files = await listAll(storageRef);
      const filePromises = [];
      for (let file of files.items) {
        filePromises.push(getDownloadURL(file));
      }
      fileUrls = await Promise.all(filePromises);
    } catch {}
    return {
      isError: false,
      data: {
        id: expositionId,
        ...data,
        fileUrls,
        likedByCurrentUser: data?.likes?.includes(auth.uid),
        files,
      },
    };
  };

  const highlight = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
      highlight: true,
    });
    await list(undefined, true);
    return get(expositionId);
  };

  const unhighlight = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
      highlight: false,
    });
    await list(undefined, true);
    return get(expositionId);
  };

  const remove = async (expositionId = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition Specified' };
    }
    const docRef = doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId);
    try {
      await removeObrasFromExposition(expositionId);
      await deleteDoc(docRef);
      const filesRef = ref(storage, expositionId);
      const files = await listAll(filesRef);
      await Promise.all(
        files.items.map((i) => {
          return deleteObject(i);
        })
      );
      cache.clear();
      return { isError: false, data: { deleted: expositionId } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const update = async (exposition: Exposition) => {
    const expositionId = exposition.id ?? defaultExpositionId;
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    const fileRefs = [];
    await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
      tematicId: exposition.tematicId,
      name: exposition.name ?? '',
      description: exposition.description ?? '',
      resume: exposition.resume ?? '',
      address: exposition.address ?? '',
      museum: exposition.museum ?? '',
      highlight: exposition.highlight ?? false,
      state: exposition.state ?? 'draft',
    });
    if (exposition.files && exposition.files.length) {
      try {
        for (const file of exposition.files as File[]) {
          const storageRef = ref(storage, `${expositionId}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        return { isError: true, error: e };
      }
    }
    const data = {
      ...exposition,
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const removeFile = async (fileRef: StorageReference) => {
    try {
      console.log(fileRef);
      await deleteObject(fileRef);
      cache.clear();
      return { isError: false, data: { deleted: true } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const list = async (tematicId?: string, fromServer = false) => {
    const storedExpos = cache.get();
    if (storedExpos && !fromServer) {
      return {
        isError: false,
        data: tematicId ? storedExpos?.filter((i: Exposition) => i.tematicId === tematicId) : storedExpos,
      };
    }
    let docs = await getDocs(collection(db, FIREBASE_EXPOSITION_COLLECTION));
    const expositions: Exposition[] = [];
    const allLoadedPromises = [];
    for (let doc of docs.docs) {
      const storageRef = ref(storage, doc.id);
      const files = await listAll(storageRef);
      const filePromises = [];
      for (let file of files.items) {
        filePromises.push(getDownloadURL(file));
      }
      const data = doc.data() as Exposition;
      const loadedPromise = Promise.all(filePromises).then((fileUrls) => {
        expositions.push({
          id: doc.id,
          ...data,
          isLikedByCurrentUser: data?.likes?.includes(auth.uid),
          files: files as any,
          fileUrls
        });
      });
      allLoadedPromises.push(loadedPromise);
    }
    await Promise.all(allLoadedPromises);
    cache.set(expositions);
    return {
      isError: false,
      data: tematicId ? expositions?.filter((i: Exposition) => i.tematicId === tematicId) : expositions,
    };
  };

  const listHighlighted = async (tematicId?: string, fromServer = false) => {
    let expositions = cache.get();
    if (!expositions || fromServer) {
      const response = await list(tematicId, fromServer);
      if (response.isError) {
        return response;
      }
      expositions = response.data;
    }
    return {
      isError: false,
      data: expositions?.filter((i: Exposition) => !!i.highlight && (tematicId ? i.tematicId === tematicId : true)),
    };
  };

  const like = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
        likes: arrayUnion(auth.uid),
      });
      const cachedItems = cache.get();
      const expoId = cachedItems.findIndex((i: Exposition) => i.id === expositionId);
      const likeSet = new Set(cachedItems[expoId].likes);
      likeSet.add(auth.uid);
      cachedItems[expoId].likes = Array.from(likeSet);
      cachedItems[expoId].isLikedByCurrentUser = true;
      cache.set(cachedItems);
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to like' };
    }
  };

  const unlike = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
        likes: arrayRemove(auth.uid),
      });
      const cachedItems = cache.get();
      const expoId = cachedItems.findIndex((i: Exposition) => i.id === expositionId);
      const likeSet = new Set(cachedItems[expoId].likes);
      likeSet.delete(auth.uid);
      cachedItems[expoId].likes = Array.from(likeSet);
      cachedItems[expoId].isLikedByCurrentUser = false;
      cache.set(cachedItems);
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to unlike' };
    }
  };

  const publish = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
        state: 'published',
      });
      await list(undefined, true);
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to publish' };
    }
  };

  const unpublish = async (expositionId: string = defaultExpositionId) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_EXPOSITION_COLLECTION, expositionId), {
        state: 'draft',
      });
      await list(undefined, true);
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to unpublish' };
    }
  };

  return {
    list,
    create,
    get,
    remove,
    update,
    removeFile,
    unlike,
    like,
    highlight,
    unhighlight,
    listHighlighted,
    publish,
    unpublish,
  };
};
