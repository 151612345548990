import { useState } from "react";
import '../../styles/login.scss';
import { useTranslation } from 'react-i18next';
import { AlertForm } from "../alertForm";
import { useAuth, useRegister } from "../user/authContext";
import { useHistory } from 'react-router-dom';
import { Spinner } from "../spinner";

export const SignIn = () => {
  const {t} = useTranslation("global");
  const [alert, setAlert] = useState({})
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    password: ''
  })

  const history = useHistory();
  const user = useAuth();

  const [disable, setDisable] = useState(false)

  const handleOnChange = (props: string) => {
    return (e: any) => setForm({...form, [props]: e.target.value})
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation();
    e.preventDefault();
    
    if([form.email, form.password, form.name, form.lastName].includes('')){
      setAlert({msg: '*Completar todos los campos', error: true})
      return;
    } 

    if(form.password.length < 6){
      setAlert({msg: '*Tu constraseña debe contener mas de 6 caracteres', error: true})
      return
    } 
    setAlert({})

    if(disable) {
      return;
    }
    setDisable(true)

    register({ email: form.email, password: form.password }, {email: form.email, name: form.name, surname: form.lastName}).then((r) => {
       if(r.isError) {
          setAlert({msg: '*Esta cuenta ya está registrada', error: true})
       } else{
        history.goBack()
       }
     }).then(() => {
       setDisable(false)
     })
  }

  const register = useRegister();

  return <form action="" className='log-form-style' onSubmit={(e) => handleOnSubmit(e)}>
    <AlertForm props={alert} /> 
    <div className='input-names'>
      <input 
        type="name" 
        name="name"
        className='cpanel-input'
        placeholder={t("login.name")}
        onChange={handleOnChange('name')} 
      />
      <input 
        type="lastName" 
        name="lastName"
        className='cpanel-input'
        placeholder={t("login.lastName")}
        onChange={handleOnChange('lastName')} 
      /> 
    </div>
    <input 
      type="mail" 
      name="mail"
      className='cpanel-input'
      placeholder='Email'
      onChange={handleOnChange('email')} 
    />
    <input 
      type="password" 
      name="password"
      className='cpanel-input'
      placeholder={t("login.password")}
      onChange={handleOnChange('password')}   
    />
    <button type='submit' className='btn-login' disabled={disable}>
      {disable ? <Spinner /> : t("login.singInBtn")}
    </button>
  </form>
}