import unlikeHeart from '../assets/emptyHeart.svg';
import likeHeart from '../assets/fullHeart.svg';
import { useExposition } from '../hooks/useExposition';
import '../styles/likes.scss';
import { useAuth } from './user/authContext';

export const Likes = ({
  expositionId,
  likes,
  isLikedByCurrentUser,
  afterLike,
}: {
  expositionId: string;
  likes: string[];
  isLikedByCurrentUser: boolean;
  afterLike: () => void;
}) => {
  const { isLoggedIn } = useAuth();
  const { like, unlike } = useExposition(expositionId);

  const likef = async () => {
    if (isLoggedIn) {
      if (isLikedByCurrentUser) {
        await unlike();
      } else {
        await like();
      }
      afterLike();
    }
  };

  return (
    <button className='like-btn' onClick={likef} disabled={!isLoggedIn}>
      <img src={isLikedByCurrentUser ? likeHeart : unlikeHeart} alt='' />
      {likes.length ?? 0}
    </button>
  );
};
