import { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/login.scss';
import imgLogin from '../assets/login-img.jpg';
import { LogIn } from '../components/login/logInForm';
import { SignIn } from '../components/login/signInForm';
import { useTranslation } from 'react-i18next';

export const Login = () => {
  const {t} = useTranslation("global");
  const [enterForm, setEnterForm] = useState(false)
  const selectForm = () => setEnterForm(!enterForm);

  return <div className="login">
      <div className="login-form">
        <h3>{enterForm ? t("login.joinTitle") : t("login.signInTitle")}</h3>
        {enterForm ? <SignIn/> : <LogIn/> }
        <Link to='/forgot-password' className={enterForm ? 'none' : 'login-options'}>{t("login.forgotPassword")}</Link>
        <div className={enterForm ? 'login-options' : 'signin-link'} onClick={selectForm}>
          {enterForm ? t("login.signInLink") : t("login.createAccount")}
        </div>
      </div>
    <div className="desktop-image">
      <img src={imgLogin} alt="" />
    </div>
  </div>
}