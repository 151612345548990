import '../styles/header.scss';
import logo from '../assets/mav.svg';
import { HashLink as Link } from 'react-router-hash-link';
import { HamburgerSlider } from 'react-animated-burgers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './user/authContext';
import ddown from '../assets/dropDown.svg';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

export const Header = () => {
  const [t, i18n] = useTranslation("global");
  
  const user = useAuth();

  const logout = () => {
    signOut(auth);
  };

  const [ isActive, setIsActive ] = useState(false)
  const toggleButton = () => setIsActive(!isActive)
  
  const [dropDown, setDropDown] = useState(false)

  const dropDownOpen = () => setDropDown(true)
  const dropDownClose = () => setDropDown(false)
  const dropDownBtn = () => setDropDown(!dropDown)

  const enterLink = <Link to='/log-in' className='login-link'>{t('header.enter')}</Link>

  const userDropDown = <div className='user-drop-down' onClick={dropDownBtn} onMouseEnter={dropDownOpen} onMouseLeave={dropDownClose}>
    <div className='user-name'>{user.name} <img src={ddown} alt=""  /></div>
    <div className={`log-out ${ dropDown ? 'show-dd' : ''}`}>
      <Link className={user.isAdmin ? 'dw-item' : 'none'} to='/cpanel/tematics'>Admin</Link>
      <button className='dw-item' onClick={logout}>Cerrar Sesión</button>  
    </div>
  </div> 

  return <div className='header' >
    <div className='header-container'>
      <Link to='/' className='brand'>
        <img src={logo} alt="" />
      </Link>
      <div className='hamburguer'>
      <HamburgerSlider 
          isActive={isActive} 
          toggleButton={toggleButton} 
          buttonColor="transparent" 
          barColor='#E99A4E' 
        />
      </div>
      <div className={`nav-menu ${isActive ? 'show-menu' : ' '}`}>
          <Link to='/museos' smooth className='link'>{t('header.tematic')}</Link>
          <Link to='/home/#about-us' smooth className='link'>{t('header.about')}</Link>
          <Link to='/home/#contact-us' smooth className='link'>{t('header.contact')}</Link>
          <a href='https://myartvisitblog.wordpress.com/' target='_blank' className='link'>{t('header.blog')}</a>
          {user.isLoggedIn ? userDropDown : enterLink   }
          <div className='lang-responsive'>
            <button onClick={() => i18n.changeLanguage("es")} className="btn-lang">ES</button>
            <span> | </span>
            <button onClick={() => i18n.changeLanguage("en")} className="btn-lang">EN</button>
          </div>
      </div>
    </div>
  </div>
} 