import { Header } from '../components/header';
import { WorkOfArt } from '../components/workOfArt';
import '../styles/expositions.scss';
import location from '../assets/locationIcon.svg';
import museum from '../assets/museum.svg';
import { Comments } from '../components/comments';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GoBack } from '../components/goBack';
import { Obra, useObra } from '../hooks/useObra';
import { Exposition, useExposition } from '../hooks/useExposition';
import { useTranslation } from "react-i18next";
import { Spinner } from '../components/spinner';

export const ExpositionPage = () => {
  window.scrollTo(0, 0);
  const [isLoading, setIsLoading] = useState(true);
  const obra = useObra();
  const [obrasList, setObrasList] = useState<Obra[]>([]);
  const { expositionId } = useParams<{ expositionId: string }>();

  const {t, i18n} = useTranslation("global")

  useEffect(() => {
    obra.list(expositionId).then((l) => {
      setObrasList(l.data);
    })
    .then(() => {
      setIsLoading(false);
    });
  }, []);

  const exposition = useExposition();
  const [expositionData, setExpositionData] = useState<Exposition>();

  useEffect(() => {
    exposition.get(expositionId).then((l) => setExpositionData(l.data as any));
  }, []);

  const worksList =  obrasList.map((art) => 
    <WorkOfArt 
      artImg={(art.fileUrls as any).mainImg}  
      mapImg={(art.fileUrls as any).map}
      audio={(art.fileUrls as any)[`audio-${i18n.language}`]}
      artTitle={art.name[i18n.language]} 
      artDescription={art.description[i18n.language]} 
      artist={art.author}
      order={art.order}
    />   
  )

  const emptyList = <p className='empty-list-msg'>No hay obras en esta exposición aún</p>;
  
  return (
    <div className='exposition'>
      <Header />
      <div className='exposition-container'>
        <GoBack text={t("goback.goback")} style='go-back-front' />
        <h2 className='font'>{expositionData?.name[i18n.language]}</h2>
        <p className='parrafo'>{expositionData?.description[i18n.language]}</p>
        <div className='museum-data'>
          <span className='gap'>
            <img src={museum} alt='' />
            {expositionData?.museum}
          </span>
          <span>
            <img src={location} alt='' />
            {expositionData?.address}
          </span>
        </div>

        <div className='recorrido'>
          { isLoading ? <Spinner /> : !obrasList.length ? emptyList : worksList }
        </div>
        <div className='resume'>
          <p>{expositionData?.resume?.[i18n.language]}</p>
        </div>
        <div className='comment-container comment-dark'>
          <Comments />
        </div>
      </div>
    </div>
  );
};
