import { useState, useEffect } from 'react';
import '../styles/comments.scss';
import { CommentCard } from './cards/commentCard';
import { useAuth } from './user/authContext';
import { useComment } from '../hooks/useComments';
import { useParams } from 'react-router-dom';
import { Spinner } from './spinner';
import { useTranslation } from "react-i18next";

export const Comments = () => {
  const user = useAuth();
  const comments = useComment();
  const {t} = useTranslation("global")

  const { expositionId } = useParams<{ expositionId: string }>();

  const [form, setFrom] = useState({
    comments: '',
  });

  const [list, setList] = useState<Comment[]>([]);
  const [disable, setDisable] = useState(false);

  const handleOnChange = (props: any) => {
    return (e: any) => setFrom({ ...form, [props]: e.target.value });
  };

  const handleOnSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (disable) {
      return;
    }
    setDisable(true);

    comments
      .create(form.comments, expositionId)
      .then(() => {
        e.target.reset();
      })
      .then(() => {
        setDisable(false);
      });
  };

  useEffect(() => {
    comments.list(expositionId).then((l) => {
      setList(l.data as any);
    });
  });

  const deleteComment = (id: string) => comments.remove(id).then(() => {});

  const commetBlocked = <p className='alert-form'>{t("comments.error")}</p>;

  return (
    <div className='comments'>
      <div className='comments-container'>
        <h2>{t("comments.title")}</h2>
        <form action='' onSubmit={(e) => handleOnSubmit(e)}>
          {user.isLoggedIn ? null : commetBlocked}
          <textarea name='' placeholder={t("comments.placeholder")} onChange={handleOnChange('comments')}></textarea>
          <button type='submit' className={`btn btn-margin ${user.isLoggedIn ? '' : 'blocked'}`} disabled={disable}>
            {disable ? <Spinner /> : t("comments.btn")}
          </button>
        </form>

        <div className='comment-list'>
          {list.map((l: any) => (
            <CommentCard
              ownerId={l.userId}
              name={l.author.name}
              surname={l.author.surname}
              comment={l.comment}
              deleteComment={() => deleteComment(l.id as any)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
