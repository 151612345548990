import '../styles/workOfArt.scss';
import arrow from '../assets/arrow.svg';
import close from '../assets/close.svg';
import location from '../assets/locationIconWhite.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { POSITION } from 'react-toastify/dist/utils';


export const WorkOfArt = (props: any) => {
  const { t } = useTranslation("global");
  const [openWork, setOpenWork] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [map, setMap] = useState(false);
  const [zoom, setZoom] = useState(false);

  const showDescription = () => setOpenWork(!openWork)
  const showPopUp = () => setPopUp(!popUp)
  const showMap = () => setMap(!map)
  const zoomIn = () => setZoom(!zoom)
  const ShowInfo = () => setOpenInfo(!openInfo)

  const imgPopUp = <div className={`pop-up ${popUp ? 'show-pop-up' : ''}`}>
    <img src={props.artImg} alt="" />
    <span onClick={showPopUp}><img src={close} alt="" />
      {t("workOfArt.close")}</span>
  </div>

  const mapPopUp = <div className={`map ${map ? 'show-map' : ''}`}>
    <img onClick={zoomIn} className={`mapImg ${zoom ? "zoom" : ''}`} src={props.mapImg} alt="" />
    <span onClick={showMap}><img src={close} alt="" />
      {t("workOfArt.close")}</span>
  </div>

  const InfoPopUp = <div className={`map ${openInfo ? 'show-text' : ''}`}>
    <img className='img-background' src={props.artImg}></img>
    <div className='close-showinfo' >
      <span onClick={ShowInfo}><img src={close} alt="" /> {t("workOfArt.close")}</span>
    </div>
    <div className='TextandTitle' >
      <div>

        <h3 className='title-in-info'>{props.artTitle}</h3>
      </div>
      <div>
        <p className='text-in-info'>{props.artDescription}</p>
      </div>
      
     
    </div>
  </div>

  return <div className="work-of-art" style={{ order: `${props.order}` }}>
    {imgPopUp}
    {mapPopUp}
    {InfoPopUp}
    <div className='obra-box'>
      <div className='obra-audio'>
        <div className='obra-img'>
          <img src={props.artImg} alt="" />
          <span className='info-btn' onClick={ShowInfo}>Info</span>
          <span className='pop-up-btn' onClick={showPopUp}>{t("workOfArt.photo")}</span>
          <span>
            <audio className='player-aac' controls>
              <source src={props.audio} />
            </audio>

          </span>
          <span className='pop-up-ubication' onClick={showMap}><img src={location} alt="" />{t("workOfArt.location")}</span>

        </div>



      </div>
      <div className='obra-container' onClick={showDescription}>

        <div className='obra-title'>
          <div className='title-box'>
            <p className='title-principal'>{props.artTitle}</p>
            <span>{t("workOfArt.artist")}: {props.artist}</span>
          </div>
          <div className='arrow-collapse arrow-hide'>
            <img src={arrow} alt="" className={openWork ? 'none' : 'arrow'} />
            <img src={close} alt="" className={openWork ? 'close' : 'none'} />
          </div>
        </div>

        <div className={`obra-description ${openWork ? 'show-description' : ''}`}>
          <p className='parrafo' >{props.artDescription}</p>
        </div>
      </div>
    </div>

  </div>
}