import { useState } from 'react';
import '../../styles/faq.scss';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/close.svg';

export const Faq = (props: any) => {
  const [openFaq, setOpenFaq] = useState(false);
  const showFaq = () => setOpenFaq(!openFaq)

  return <div className='faq-drop-down white-text' onClick={showFaq}>
  <div className='question title-white'>
    <p>{props.question}</p>
    <div>
      <img src={arrow} alt="" className={`${openFaq ? 'none' : 'arrow'}`}/>
      <img src={close} alt="" className={`${openFaq ? 'close' : 'none' }`}/>
    </div>
  </div>
  <div className={`answer ${openFaq ? 'show-answer' : ''}`}>{props.answer}</div>
</div>
}