import { ContactUs } from "../components/contactUs"
import { Faqs } from "../components/faq/faqs"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { AboutUs } from "../components/home/aboutUs"
import { Cover } from "../components/home/cover"
import { TopExpositions } from "../components/home/topExpositions"

export const Home = () =>{
  return <div>
    <Header />
    <Cover />
    <TopExpositions />
    <AboutUs />
    <Faqs />
    <ContactUs />
    <Footer />
  </div>
}