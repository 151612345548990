import '../../styles/cards.scss';
import { Link } from 'react-router-dom';
//import recorrido from '../../assets/recorrido1.png';



export const TematicCard = (props: any) => {
  return <div className='card-container'> 
    <Link to={`/recorridos/${props.tematicId}`} className='card'>
    <div className='card-img'><img src={props.tematicImg} alt="" /></div>
    <div className='card-title'>
      <p>{props.tematicName}</p>
    </div>
  </Link>
</div>
}