import { useState } from 'react';
import '../styles/deleteBtn.scss';
import trash from '../assets/trash.svg';

export const DeleteBtn = (props: any) => {
  const [deleteBtn, setDeleteBtn] = useState(false);
  const db = () => setDeleteBtn(!deleteBtn);

  return <div className='delete-btn' onClick={db}>
  <div className='delete-btn-dd'><img src={trash} alt="" /></div>
  <div className={`delete ${deleteBtn ? 'show-delete' : ''}`} onClick={props.onClick}>Borrar</div>
</div>
}