import '../styles/headerBackOffice.scss';
import { useState } from 'react';
import { useAuth } from '../components/user/authContext';
import arrow from '../assets/dropDown.svg';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { Link } from 'react-router-dom';
import logo1 from '../assets/mav.svg';

export const HeaderBackOffice = () =>{
  const user = useAuth();

  const logout = () => {
    signOut(auth);
  };

  const [dropDown, setDropDown] = useState(false)
  const dropDownEnter = () => setDropDown(true)
  const dropDownOut = () => setDropDown(false)
  
  
 
  return <div className="header-back-office">
    <div className='brand-backoffice'>
      <Link to='/cpanel/tematics'><img src={logo1} alt="" /></Link>
    </div>
    <div className='user-logout-backoffice' onMouseEnter={dropDownEnter} onMouseLeave={dropDownOut}>
      <div className='user-name-backoffice'>
        {user.name} {user.surname} <img src={arrow} alt="" />
      </div>
      <button className={`logout-btn-backoddice ${dropDown ? 'show-lbt' : ''}`} onClick={logout}>
        Cerrar Sesión
      </button>
    </div>
  </div>
}