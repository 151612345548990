import { Link } from 'react-router-dom';
import '../../styles/cover.scss';
import { useTranslation } from 'react-i18next';
import SimpleImageSlider from "react-simple-image-slider";
import cover1 from '../../assets/cover1.jpg'
import cover2 from '../../assets/cover2.jpg'
import cover3 from '../../assets/cover3.jpg'
import cover4 from '../../assets/cover4.jpg'
import cover5 from '../../assets/cover5.jpg'
import cover1Tablet from '../../assets/cover1Tablet.jpg'
import cover2Tablet from '../../assets/cover2Tablet.jpg'
import cover3Tablet from '../../assets/cover3Tablet.jpg'
import cover4Tablet from '../../assets/cover4Tablet.jpg'
import cover5Tablet from '../../assets/cover5Tablet.jpg'
import cover1M from '../../assets/cover1M.jpg'
import cover2M from '../../assets/cover2M.jpg'
import cover3M from '../../assets/cover3M.jpg'
import cover4M from '../../assets/cover4M.jpg'
import cover5M from '../../assets/cover5M.jpg'


export const Cover = () =>{
  const {t} = useTranslation("global")

  const images = [
    cover1, cover3, cover4, cover5
  ];

  const desktop = <SimpleImageSlider
  width={`100%`}
  height={`100%`}
  images={images}
  showBullets={true}
  showNavs={false}
  autoPlay={true}
  autoPlayDelay={6}
  slideDuration={2}
  style={{opacity: "0.7"}}
/>

const imagesTablet = [
  cover1M, cover3M, cover4M, cover5M
];

const tablet = <SimpleImageSlider
width={`100%`}
height={`100%`}
images={imagesTablet}
showBullets={true}
showNavs={false}
autoPlay={true}
autoPlayDelay={6}
slideDuration={2}
style={{opacity: "0.7"}}
/>

const imagesMobile = [
  cover1Tablet, cover3Tablet, cover4Tablet, cover5Tablet
];

const mobile = <SimpleImageSlider
width={`100%`}
height={`100%`}
images={imagesMobile}
showBullets={true}
showNavs={false}
autoPlay={true}
autoPlayDelay={6}
slideDuration={2}
style={{opacity: "0.7"}}
/>

  return <div className='cover'>
      <div className='cover-container'>
        <div className='cover-img'>
          <div className='desktop'>{desktop}</div>
          <div className='tablet'>{tablet}</div>
          <div className='mobile'>{mobile}</div>
        </div>
        <div className='cover-title'>
          <h1>{t("cover.title1")}<br/>{t("cover.title2")}</h1>
          <p>{t("cover.subtitle")}</p>
          <Link to='/museos' className='btn'>{t("cover.cta")}</Link>
        </div>
      </div>
    </div>
}