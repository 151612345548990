import { Tematic, useTematic } from "../../hooks/useTematic";
import { useState, useEffect } from "react";
import { HeaderBackOffice } from "../headerBackOffice";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { GoBack } from "../../components/goBack";
import { Spinner } from "../../components/spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EditTematic = () =>{
  const tematic = useTematic();
  const {tematicId} = useParams<{tematicId: string}>()
  const [form, setForm] = useState<any>({})
  
  useEffect(() => {
    tematic.get(tematicId).then(async (response) => {
      setForm({
        imageRef: (response.data?.files as any)?.items?.[0],
        image: response.data?.fileUrls?.[0],
        tematicEs: response.data?.name.es,
        tematicEn: response.data?.name.en, 
        descriptionEs: response.data?.description.es,
        descriptionEn: response.data?.description.en
      })
    })
  }, [])



  const deleteImg = () => tematic.removeFile(form.imageRef).then(() => {
    setForm({ ...form, imageRef: null, image: null})
  })

  
  const [ error, setError] = useState(false)
  const [disable, setDisable] = useState(false)
  

  const handleOnChange = (props: any) => {
    return (e: any) => setForm({...form, [props]:e.target.value})
  }

  const handleImage = (props: any) => {
    return (e: any) => setForm({...form, [props]: e.currentTarget.files[0] })
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation()
    e.preventDefault();

    if(form.tematicEs === '' || form.tematicEn === '' || form.descriptionEs === '' || form.descriptionEn === '') {
      return setError(true);
    }
    setError(false)

    if(disable){
      return;
    }
    setDisable(true);

    tematic.update({
      id: tematicId,
      files: form.imageNew ? [form.imageNew] : [],
      name: {es: form.tematicEs, en: form.tematicEn},
      description: {es: form.descriptionEs, en: form.descriptionEn},
    }).then(() => {
      toast.success('Modificación existosa')
      setDisable(false)
    })
  }

  return <div className="cpanel-form-page">
  <HeaderBackOffice />
  <div className="cpanel-sub-header">
  <div>
    <GoBack />
    <h3>Editar temática</h3>
  </div>
</div>
  <div className="cpanel-form-container">
    <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e) } >
      {form.image === null && form.imageRef === null ? <>
        <label className="cpanel-label">Seleccionar nueva imagen de temática</label>
        <input 
          type="file" 
          name="image" 
          className='cpanel-file'
          onChange={handleImage('imageNew')} 
        />
        
      </> 
        : 
      <>
       <img src={form.image} style={{height: "100px", width: "100px"}} alt='' />
        <div onClick={() => deleteImg()}>Borrar</div>
      </>
      }
    
      <br />
      <label className="cpanel-label">Nombre de la temática (Español)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input"
        value={form.tematicEs}
        onChange={handleOnChange('tematicEs')} 
      />
       <label className="cpanel-label">Sobre la temática (Español)</label>
      <textarea 
        name="description" 
        className="cpanel-textarea"
        value={form.descriptionEs}
        onChange={handleOnChange('descriptionEs')} 
      >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Nombre de la temática (Inglés)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input"
        value={form.tematicEn}
        onChange={handleOnChange('tematicEn')} 
      />
      <label className="cpanel-label">Sobre la temática (Inglés)</label>
      <textarea 
        name="description" 
        className="cpanel-textarea"
        value={form.descriptionEn}
        onChange={handleOnChange('descriptionEn')} 
      >
      </textarea>
      <button type='submit' className='btn-dashboard'>{disable ? <Spinner /> :'Modificar Temática'}</button>
    </form>
    <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
  </div>
</div>
}
