import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../components/user/authContext';

export const IsAdmin = (component: any) => {
  const user = useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!user.isLoadingAuth) {
      setReady(true);
    }
  }, [user.isLoadingAuth]);

  return ready && (!user.isLoggedIn || !user.isAdmin) ? () => <Redirect to='/' /> : component;
};
