import { WorkOfArt } from "../components/workOfArt";
import '../styles/expositions.scss';
import location from '../assets/locationIcon1.svg';
import museum from '../assets/museum1.svg';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GoBack } from "../components/goBack";
import { Obra, useObra } from "../hooks/useObra";
import { Exposition, useExposition } from "../hooks/useExposition";
import { useTranslation } from "react-i18next";

export const Preview = () => {
  const obra = useObra();
  const [obrasList, setObrasList] = useState<Obra[]>([]) 
  const {expositionId} = useParams<{expositionId: string}>();

  const {t, i18n} = useTranslation("global")
  
  useEffect(() => {
    obra.list(expositionId).then((l) => {
      setObrasList(l.data)
    })
  },[])

  const exposition = useExposition()
  const [expositionData, setExpositionData] = useState<Exposition>()

  useEffect(() =>{
    exposition.get(expositionId).then((l) => {
      setExpositionData(l.data as any)
      console.log(l.data)
    } 
      
    )
  }, [])

  return <div className='exposition '>
    <h2 className="preview-title">vista previa</h2>
    <div className='exposition-container exposition-preview'>
      <GoBack text='Volver atrás' style='go-back-front'/>
      <h2 className="font">{expositionData?.name.es}</h2>
      <p>{expositionData?.description.es}</p>
      <div className="museum-data">
        <span className="gap"><img src={museum} alt="" />{expositionData?.museum}</span>
        <span><img src={location} alt="" />{expositionData?.address}</span>
      </div>
      <div className="recorrido">
        {obrasList.map((art) => 
          <WorkOfArt 
            artImg={(art.fileUrls as any).mainImg} 
            mapImg={(art.fileUrls as any).map}
            audio={(art.fileUrls as any)[`audio-${i18n.language}`]}
            artTitle={art.name.es} 
            artDescription={art.description.es} 
            artist={art.author}
            order={art.order}
          />   
        )}
      </div>
      <div className='resume'>
        <p>{expositionData?.resume?.[i18n.language]}</p>
      </div>
    </div>
  </div>
}
