const DEFAULT_EXPIRATION_TIME = 60 * 20 * 1000;

export const useCache = (cacheName: string, expirationTimeInMs: number = DEFAULT_EXPIRATION_TIME) => {
  localStorage.getItem(cacheName);

  const get = () => {
    const cache = JSON.parse(localStorage.getItem(cacheName) ?? 'null');
    if (!cache) {
      return null;
    }
    const isExpired = cache.creationTime < Date.now() - expirationTimeInMs;
    if (isExpired) {
      clear();
    }
    return !isExpired ? cache.content : null;
  };

  const set = (content: any) => {
    localStorage.setItem(cacheName, JSON.stringify({ content, creationTime: Date.now() }));
  };

  const clear = () => localStorage.removeItem(cacheName);

  return {
    set,
    get,
    clear,
  };
};
