import { HeaderBackOffice } from "../headerBackOffice";
import '../../styles/dashboard.scss';
import { useState } from "react";
import { GoBack } from "../../components/goBack"; 
import { useTematic } from "../../hooks/useTematic";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../components/spinner";

export const TematicForm = () =>{
  const [form, setForm] = useState({
    image: [],
    tematicEs: '',
    tematicEn: '', 
    descriptionEs: '',
    descriptionEn: ''
  })
  const [ error, setError] = useState(false)

  const tematic = useTematic()
  const history = useHistory()

  const [disable, setDisable] = useState(false) 

  const handleOnChange = (props: any) => {
    return (e: any) => setForm({...form, [props]:e.target.value})
  }

  const handleImage = (props: any) => {
    return (e: any) => setForm({...form, [props]:e.currentTarget.files})
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation()
    e.preventDefault();

    if(!form.image.length || form.tematicEs === '' || form.tematicEn === '' || form.descriptionEs === '' || form.descriptionEn === '') {
      return setError(true);
    }
    setError(false)

    if(disable) {
      return;
    }
    setDisable(true)

    tematic.create({
      name: {es: form.tematicEs, en: form.tematicEn}, 
      description: {es: form.descriptionEs, en: form.descriptionEn } , 
      files: form.image
    })
    .then(() =>{
      history.goBack()
    })
  }

  return <div className="cpanel-form-page">
    <HeaderBackOffice />
    <div className="cpanel-sub-header">
    <div>
      <GoBack />
      <h3>Agregar Temática</h3>
    </div>
  </div>
    <div className="cpanel-form-container">
      <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e) } >
        <label className="cpanel-label">Seleccionar imagen de la temática</label>
        <input 
          type="file" 
          name="image" 
          className='cpanel-file'
          onChange={handleImage('image')} 
        />
        <br />
        <label className="cpanel-label">Nombre de la temática (Español)</label>
        <input 
          type="text" 
          name="title" 
          className="cpanel-input"
          onChange={handleOnChange('tematicEs')} 
        />
         <label className="cpanel-label">Sobre la temática (Español)</label>
        <textarea 
          name="description" 
          className="cpanel-textarea"
          onChange={handleOnChange('descriptionEs')} 
        >
        </textarea>
        <br /><hr /><br />
        <label className="cpanel-label">Nombre de la temática (Inglés)</label>
        <input 
          type="text" 
          name="title" 
          className="cpanel-input"
          onChange={handleOnChange('tematicEn')} 
        />
        <label className="cpanel-label">Sobre la temática (Inglés)</label>
        <textarea 
          name="description" 
          className="cpanel-textarea"
          onChange={handleOnChange('descriptionEn')} 
        >
        </textarea>
        <button type='submit' className='btn-dashboard' disabled={disable}>
          {disable ? <Spinner /> : 'Crear Temática'}  
        </button>
      </form>
      {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
    </div>
  </div>
}