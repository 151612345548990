import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { useAuth } from '../components/user/authContext';
import { db, storage } from '../firebase/firebaseConfig';
import { useCache } from './useCache';

const FIREBASE_COMMENTS_COLLECTION = 'comments';

export type Comment = {
  id: string;
  expositionId: string;
  ownerId: string;
  author: {
    name: string;
    surname: string;
  };
  likes: string[];
  comment: string;
};

export const useComment = (defaultExpositionId: string = '') => {
  const cache = useCache('COMMENTS');

  const auth = useAuth();
  const create = async (comment: string, expositionId: string = defaultExpositionId) => {
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No user logged in to add comment' };
    }
    if (!comment) {
      return { isError: true, error: 'No comment to add' };
    }
    if (!expositionId) {
      return { isError: true, error: 'You need to specify the exposition' };
    }
    const author = {
      name: auth.name ?? '',
      surname: auth.surname ?? '',
    };
    const newComment = await addDoc(collection(db, FIREBASE_COMMENTS_COLLECTION), {
      expositionId,
      userId: auth.uid,
      author,
      comment,
      likes: [],
    });

    const createdComment = await getDoc(newComment);
    const data = createdComment.data();
    cache.clear();
    return { isError: false, data };
  };

  const remove = async (commentId: string) => {
    if (!commentId) {
      return { isError: true, error: 'No comment Specified' };
    }
    const docRef = doc(db, FIREBASE_COMMENTS_COLLECTION, commentId);
    try {
      await deleteDoc(docRef);
      const filesRef = ref(storage, commentId);
      await deleteObject(filesRef);
      cache.clear();
      return { isError: false, data: { deleted: commentId } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const list = async (expositionId?: string) => {
    const cachedItems = cache.get();
    const comments: Comment[] = cachedItems ?? [];
    if (!cachedItems) {
      let docs = await getDocs(collection(db, FIREBASE_COMMENTS_COLLECTION));
      docs.forEach((doc) => {
        const data = doc.data();
        comments.push({ id: doc.id, ...data, isLikedByCurrentUser: data.likes?.includes(auth.uid) } as any);
      });
    }
    return { isError: false, data: expositionId ? comments?.filter((c) => c.expositionId === expositionId) : comments };
  };

  const like = async (commentId: string) => {
    if (!commentId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_COMMENTS_COLLECTION, commentId), {
        likes: arrayUnion(auth.uid),
      });
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to like' };
    }
  };

  const unlike = async (commentId: string) => {
    if (!commentId) {
      return { isError: true, error: 'No exposition specified' };
    }
    if (!auth.isLoggedIn) {
      return { isError: true, error: 'No logged in user to perform this action' };
    }
    try {
      await updateDoc(doc(db, FIREBASE_COMMENTS_COLLECTION, commentId), {
        likes: arrayRemove(auth.uid),
      });
      return { isError: false };
    } catch (e) {
      return { isError: true, error: 'Failed to unlike' };
    }
  };

  return { list, create, remove, like, unlike };
};
