import { Link } from 'react-router-dom';
import '../styles/dashboard.scss';
import { CardDashboard } from './cardDashboard';
import { HeaderBackOffice } from './headerBackOffice';
import { NavBar } from './navbar';
import { Exposition, useExposition } from '../hooks/useExposition';
import { useState, useEffect } from 'react';
import { Spinner } from '../components/spinner';
import { async } from '@firebase/util';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ExpositionCard } from './expositionCard';


export const MyExpositions = (props: any) => {
  const exposition = useExposition();
  const [expositionList, setExpositionList] = useState<Exposition[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const refreshList = (fromServer = false) => {
    exposition
      .list(undefined, fromServer)
      .then((l) => {
        const expositionCard = l.data;
        setExpositionList(expositionCard);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => refreshList(), []);

  const deleteExposition = (id: string) => {
    setIsLoading(true);
    exposition.remove(id).then(() => {
      exposition.list()
        .then(l => {
          if(!l.isError) {
            setExpositionList(l.data);
          }
          setIsLoading(false);
      }).then(response =>{
        toast.success('Recorrido eliminado')
      }) 
    })
  }


  const completeList = expositionList.map((l) => {
    return (
      <ExpositionCard
        title={l.name.es}
        isHighlight={l.highlight}
        state={l.state}
        obras='Ver obras'
        expositionId={l.id}
        deleteBtn={() => deleteExposition(l.id as string)}
        hideLike
        highlight={async () => {
          setIsLoading(true);
          await (l.highlight ? exposition.unhighlight(l.id) : exposition.highlight(l.id));
          refreshList();
        }}
        publish={async () => {
          setIsLoading(true);
          await (l.state === 'draft' ? exposition.publish(l.id) : exposition.unpublish(l.id));
          refreshList();
        }}
        previewId={l.id}
        previewLink='Vista previa'
      />
    );
  });

  const emptyList = <p className='empty-list-msg'>No hay exposiciones creadas</p>;

  return <div className='c-panel-dashboard'>
      <HeaderBackOffice />
      <div className='dashboard-container'>
        <NavBar />
        <div className='general-dashboard-container'>
          <div className='btn-dashboard-container'>
            <Link to='/cpanel/add-exposition-form' className='btn-dashboard'>
              + Agregar recorrido
            </Link>
          </div>
          <div className='card-dashboard-container'>
            <h3>Lista de recorridos</h3>
            <div className='card-dashboard-list'>
              {isLoading ? (
                <div className='spinner-p'>
                  <Spinner />
                </div>
              ) : !expositionList.length ? (
                emptyList
              ) : (
                completeList
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  }

