import { Header } from '../components/header';
import '../styles/expositions.scss';
import '../styles/cards.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ExpositionCard } from '../components/cards/ExpositionCard';
import { GoBack } from '../components/goBack';
import { Exposition, useExposition } from '../hooks/useExposition';
import { Tematic, useTematic } from '../hooks/useTematic';
import { Spinner } from '../components/spinner';
import { useTranslation } from 'react-i18next';

export const Expositions = () => {
  window.scrollTo(0, 0);
  const { t, i18n } = useTranslation('global');

  const { tematicId } = useParams<{ tematicId: string }>();
  const [expositionList, setExpositionList] = useState<Exposition[]>([]);
  const [tematicData, settematicData] = useState<Tematic>();
  const [isLoading, setIsLoading] = useState(true);

  const exposition = useExposition();
  const tematic = useTematic();

  const refresh = (forceRefresh = false) =>
    exposition
      .list(tematicId, forceRefresh)
      .then((l) => {
        const expositions = l.data.filter((e: any) => e.state === 'published');
        setExpositionList(expositions);
      })
      .then(() => {
        setIsLoading(false);
      });

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    tematic.get(tematicId).then((t) => {
      settematicData(t.data as any);
    });
  }, []);

  const completeList = expositionList.map((ex) => {
    return <ExpositionCard onLikeUnlike={() => refresh()} exposition={ex} />;
  });

  const emptyList = <p className='empty-list-msg'>No hay exposiciones en esta temática aún</p>;

  return (
    <div className='exposition'>
      <Header />
      <div className='exposition-container'>
        <GoBack text={t('goback.goback')} style='go-back-front' />
        <h2>{tematicData?.name[i18n.language]}</h2>
        <p className='parrafo'>{tematicData?.description[i18n.language]}</p>
        {isLoading ? <Spinner /> : !expositionList.length ? emptyList : <div className='mav-grid'>{completeList}</div>}
      </div>
    </div>
  );
};
