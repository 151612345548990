import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../styles/cards.scss';
import { Likes } from '../likes';

export const ExpositionCard = (props: any) => {

  const {t, i18n} = useTranslation("global")
  return (
    <div className='card-container'>
      <Link to={`/muestra/${props.exposition?.id}`} className='card'>
        <div className='card-img'>
          <img src={props.exposition?.fileUrls?.[0]} alt='' />
        </div>
        <div className='card-title'>
          <p>{props.exposition?.name[i18n.language]}</p>
        </div>
      </Link>
      <Likes
        expositionId={props.exposition.id}
        likes={props.exposition.likes}
        isLikedByCurrentUser={props.exposition.isLikedByCurrentUser}
        afterLike={() => props.onLikeUnlike()}
      />
    </div>
  );
};
