import { HeaderBackOffice } from "./headerBackOffice";
import '../styles/dashboard.scss';
import { NavBar } from "./navbar";
import { Link } from 'react-router-dom';
import '../styles/dashboard.scss';
import { CardDashboard } from './cardDashboard';
import { useTematic } from "../hooks/useTematic";
import { useEffect, useState } from 'react';
import { Spinner } from "../components/spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddTematic = () =>{
  const tematic = useTematic() 
  const [list, setList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    tematic.list().then((l) => {
      const tematicList = l.data;
      setList(tematicList)
    })
    .then(() => {
      setIsLoading(false)
    })
  }, [])

  const deleteExposition = (id: string) => {
    setIsLoading(true);
    tematic.remove(id).then(() => {
      tematic.list().then((l) => {
        const tematicList = l.data;
        setList(tematicList)
      })
      setIsLoading(false)
    }).then(response =>{
      toast.success('Recorrido eliminado')
    })  
  }
    
  const completeList =  list.map((l: any) => {
    return <CardDashboard 
      tematicaId={l.id} 
      title={l.name.es} 
      deleteBtn={() => deleteExposition(l.id as string)}
    />
  })

  const emptyList = <p className='empty-list-msg'>No hay tematicás creadas</p>

  return <div className="c-panel-dashboard">
    <HeaderBackOffice />
    <div className="dashboard-container">
      <NavBar />
      <div className='general-dashboard-container'>
    <div className='btn-dashboard-container'>
      <Link to='/cpanel/add-tematic-form' className='btn-dashboard'>+ Agregar Museo</Link>
    </div>
    <div className='card-dashboard-container'>
      <h3>Lista de museos</h3>
      <div className='card-dashboard-list'>
        {isLoading ? <div className="spinner-p"><Spinner /></div>  : !list.length ? emptyList : completeList}
      </div>
    </div>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </div>
    </div>
  </div>
}