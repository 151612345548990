import { GoBack } from "../../components/goBack"
import { HeaderBackOffice } from "../headerBackOffice"
import { useExposition } from "../../hooks/useExposition"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "../../components/spinner";
import { Tematic, useTematic } from "../../hooks/useTematic";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const EditExposition = () => {
  const exposition = useExposition();
  const {expositionId} = useParams<{expositionId: string}>()

  useEffect(() => {
    exposition.get(expositionId).then(async (response) => {
      setForm({
        imageRef: (response.data?.files as any)?.items?.[0],
        image: response.data?.fileUrls?.[0],
        tematicId: response.data?.tematicId,
        titleEs: response.data?.name.es,
        titleEn: response.data?.name.en,
        descriptionEs: response.data?.description.es,
        descriptionEn: response.data?.description.en,
        resumeEs: response.data.resume.es,
        resumeEn: response.data.resume.en,
        museum: response.data?.museum,
        address: response.data?.address
      })
    })
  }, []) 

  const [form, setForm] = useState<any>({})
  const [ error, setError] = useState(false)
  const [disable, setDisable] = useState(false)

  const [TematicOptions, setTematicOptions ] = useState<Tematic[]>([])
  
  const deleteImg = () => exposition.removeFile(form.imageRef).then((r) => {
    if(!r.isError) {
      setForm({ ...form, imageRef: null, image: null })
    } else {
      console.log(r.error)
    }
  })

  const handleOnChange = (props: any) => {
    return (e: any) => setForm({...form, [props]: e.target.value })
  }

  const handleImage = (props: any) => {
    return (e: any) => {
      console.log(e.currentTarget.files[0]);
      setForm({...form, [props]: e.currentTarget.files[0] })
    }
  }

  const handleOnSubmit = (e: any) =>{
    e.stopPropagation()
    e.preventDefault();

    if(form.tematicEs === '' || form.tematicEn === '' || form.descriptionEs === '' || form.descriptionEn === '') {
      return setError(true);
    }
    setError(false)

    if(disable){
      return;
    }
    setDisable(true);

    exposition.update({
      id: expositionId,
      files: form.imageNew ? [form.imageNew] : [],
      name: {es: form.titleEs ,en: form.titleEn},
      description: {es: form.descriptionEs, en:form.descriptionEn},
      resume: {es: form.resumeEs, en: form.resumeEn},
      tematicId: form.tematicId,
      address: form.address, 
      museum: form.museum
    }).then(() => {
      toast.success('Modificación existosa')
      setDisable(false)
    })
  }

  const tematic = useTematic();
  useEffect(() => {
    tematic.list().then((l) => {
      const tematicOptions = l.data;
      setTematicOptions(tematicOptions)
    })
  }, [])


  return <div className="cpanel-form-page">
  <HeaderBackOffice />
  <div className="cpanel-sub-header">
    <div>
      <GoBack />
      <h3>Editar recorrido</h3>
    </div>
  </div>
  <div className="cpanel-form-container">
    <form action="" className="cpanel-form" onSubmit={(e) => handleOnSubmit(e)}>
    {!form.image && !form.imageRef ? <>
        <label className="cpanel-label">Seleccionar nueva imagen de temática</label>
        <input 
          type="file" 
          name="image" 
          className='cpanel-file'
          onChange={handleImage('imageNew')} 
        /> 
      </> 
        : 
      <>
       <img src={form.image} style={{height: "100px", width: "100px"}} alt='' />
        <div onClick={() => deleteImg()}>Borrar</div>
      </>
      }
      <label className="cpanel-label">Temática</label>
      <select className="cpanel-select" onChange={handleOnChange('tematicId')} >
        <option value="" hidden>Seleccionar Temática</option>
        {TematicOptions.map((t) => {
          return <option value={t.id}>{t.name.es}</option>
        })}
      </select>
      <br /><hr /><br />
      <label className="cpanel-label">Título del recorrido (Español)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input" 
        value={form.titleEs}
        onChange={handleOnChange('titleEs')}
        
      />
       <label className="cpanel-label">Descripción breve (Español)</label>
      <textarea 
        name="description" 
        id="" 
        className="cpanel-textarea"
        value={form.descriptionEs}
        onChange={handleOnChange('descriptionEs')}
        >
      </textarea>
      <br /><hr /><br />
      <label className="cpanel-label">Título del recorrido (Inglés)</label>
      <input 
        type="text" 
        name="title" 
        className="cpanel-input" 
        value={form.titleEn}
        onChange={handleOnChange('titleEn')}
      />
      <label className="cpanel-label">Descripción breve (Inglés)</label>
      <textarea 
        name="description" 
        id="" 
        className="cpanel-textarea"
        value={form.descriptionEn}
        onChange={handleOnChange('descriptionEn')}

        ></textarea>
        <br /><hr /><br />
      <label className="cpanel-label">Museo</label>
      <input 
        type="text" 
        name="museum" 
        className="cpanel-input" 
        value={form.museum}
        onChange={handleOnChange('museum')}
        
      />
       <label className="cpanel-label">Dirección del museo</label>
      <input 
        type="text" 
        name="address" 
        className="cpanel-input" 
        value={form.address} 
        onChange={handleOnChange('address')}
        
      />
       <br /><hr /><br />
      <label className="cpanel-label">Resumen (Español)</label>
      <textarea 
        name="resume"  
        className="cpanel-textarea"
        value={form.resumeEs}
        onChange={handleOnChange('resumeEs')}

        ></textarea>
        <label className="cpanel-label">Resumen (Inglés)</label>
      <textarea 
        name="resume"  
        className="cpanel-textarea"
        value={form.resumeEn}
        onChange={handleOnChange('resumeEn')}

        ></textarea>
      <button type='submit' className='btn-dashboard' disabled={disable}>
      {disable ? <Spinner /> : 'Crear Recorrido'}  
        
        </button>
    </form>
    {error ? <p className="error">*Todos los campos son obligatorios</p> : null}
  </div>
  <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
</div>
}
