import { Link } from 'react-router-dom';
import '../styles/dashboard.scss';

export const NavBar = () =>{
  return <div className="navbar">
    <div className="navbar-container">
      <div className='nav-section-height'>
        <h5>MENU</h5>
        <div className='navbar-links-container'>
        <div className='navbar-links'>
          <Link to='/cpanel/tematics' className='navbar-links to'>Museos</Link> 
        </div> 
        <div className='navbar-links'>
          <Link to='/cpanel/expositions' className='navbar-links to'>Recorridos</Link>  
          </div>
        <div className='navbar-links'>
          <Link to='/cpanel/art-form' className='navbar-links to'>Nueva obra</Link>  
        </div>
        </div>
      </div>
      <div className='nav-section-height'>
        <div className='go-home-btn'>
          <Link to='/' className='go-home-link'>Home web</Link>  
        </div>
      </div>
    </div>
  </div>
}