import { TematicCard } from "../components/cards/tematicCard";
import { Header } from "../components/header";
import { useTranslation } from "react-i18next";
import '../styles/museums.scss';
import { GoBack } from "../components/goBack";
import { useTematic } from "../hooks/useTematic";
import { useEffect, useState } from "react";
import { Spinner } from "../components/spinner";

export const Tematics = () =>{
  const {t, i18n} = useTranslation("global")
  const tematic = useTematic()
  const [list, setList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() =>{
    tematic.list()
    .then((l) =>{
      const tematicCards = l.data
      setList(tematicCards)
    })
    .then(() => {
      setIsLoading(false)
    })
  }, [])
  
  const completeList = list.map((l: any) => {
    return <TematicCard tematicId={l.id} tematicImg={l.fileUrls?.[0]} tematicName={l.name[i18n.language]} />} 
  )

  const emptyList = <p className='empty-list-msg'>No se encuentran temáticas</p>

  return <div className="museums">
    <Header />
    <div className='museums-container'>
      <GoBack text={t("goback.goback")} style='go-back-front'/>
      <h2>{t("themes.themes")}</h2>
      {isLoading ? <div className="spinner-p"><Spinner /></div> : !list.length ? emptyList :
        <div className="mav-grid">
          {completeList} 
        </div>  
      }
    </div>
  </div>
}