import '../../styles/faq.scss';
import { Faq } from './faq';
import { useTranslation } from 'react-i18next';

export const Faqs = () => {
  const {t} = useTranslation("global");

  return <div className="faqs">
    <div className="faqs-container">
      <h2 className='title-white'>{t("faqs.title")}</h2>
      <div className="faq-list title-white">
        <Faq 
          question={t("faqs.faq1")}
          answer={t("faqs.answer1")}
        />
        <Faq 
          question={t("faqs.faq2")}
          answer={t("faqs.answer2")}
        />
        <Faq 
          question={t("faqs.faq3")}
          answer={t("faqs.answer3")}
        />
        <Faq 
          question={t("faqs.faq4")}
          answer={t("faqs.answer4")}
        />
        <Faq 
          question={t("faqs.faq5")}
          answer={t("faqs.answer5")}
        />
      </div>
    </div>
  </div>
}