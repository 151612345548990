import { Link } from 'react-router-dom';
import { DeleteBtn } from '../components/deleteBtn';
import unlikeHeart from '../assets/emptyStar.svg';
import likeHeart from '../assets/fullStar.svg';
import '../styles/cards.scss';

export const ExpositionCard = (props: any) => {
  return (
    <div className='card-dashboard-box'>
      <div className='card-title-dashboard'>{props.title}</div>
      <div className='edit-delete-btn'>
        <button onClick={() => props.publish()} className='edit btn-highlight'>
          {props.state === 'draft' ? 'Publicar' : 'Marcar como borrador'}
        </button>
        <button onClick={() => props.highlight()} className='edit btn-highlight'>
          <img src={props.isHighlight ? likeHeart : unlikeHeart} alt='' />
        </button>
        <Link to={`/cpanel/preview/${props.previewId}`} className='edit e-color'>
          {props.previewLink}
        </Link>
        <Link to={`/cpanel/my-works-of-art/${props.expositionId}`} className='edit e-color'>
          {props.obras}
        </Link>
        <Link to={`/cpanel/edit-exposition/${props.expositionId}`} className='edit'>
          Editar
        </Link>
        <DeleteBtn onClick={props.deleteBtn} />
      </div>
    </div>
  );
};
