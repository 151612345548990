import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './components/routes';
import './styles/global.scss';
import './styles/fonts/stylesheet.css';
import { UserContextProvider } from './components/user/authContext';

function App() {
  window.scrollTo(0,0);

  return (
    <UserContextProvider>
      <Router>
        <Routes /> 
      </Router>
    </UserContextProvider>
  );
}

export default App;
