import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getMetadata, listAll, ref, StorageReference, uploadBytes } from 'firebase/storage';
import { db, storage } from '../firebase/firebaseConfig';
import { useCache } from './useCache';

const FIREBASE_OBRAS_COLLECTION = 'obras';

export type Obra = {
  id?: string;
  name: Record<string, string>;
  expositionId: string;
  description: Record<string, string>;
  author?: string;
  files?: Record<string, File>;
  fileUrls?: string[];
  map?: any;
  audio?: any;
  order?: any;
};

export const useObra = (defaultObraId: string = '') => {
  const cache = useCache('OBRAS');

  const create = async (obra: Obra) => {
    if (!obra.expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    const fileRefs = [];
    const newObra = await addDoc(collection(db, FIREBASE_OBRAS_COLLECTION), {
      name: obra.name ?? '',
      description: obra.description ?? '',
      author: obra.author ?? '',
      expositionId: obra.expositionId,
      order: obra.order
    });
    if (obra.files) {
      const rollbackObra = () => deleteDoc(newObra);
      try {
        for (const file of Object.values(obra.files)) {
          const storageRef = ref(storage, `${newObra.id}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        await rollbackObra();
        return { isError: true, error: e };
      }
    }
    const createdObra = await getDoc(newObra);
    const data = {
      ...createdObra.data(),
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const get = async (obraId = defaultObraId) => {
    if (!obraId) {
      return { isError: true, error: 'No obra specified' };
    }
    const cachedItems = cache.get();
    const requestedItem = cachedItems?.find((i: Obra) => i.id === obraId);
    if (requestedItem) {
      const storageRef = ref(storage, obraId);
      let files, fileData;
      try {
        files = await listAll(storageRef);
        const filePromises = [];
        for (let file of files.items) {
          filePromises.push(getDownloadURL(file));
        }
        fileData = await Promise.all(filePromises).then((promises) => {
          return promises.reduce((acc: any, p: any) => {
            const [fileName] = p.metadata.name.split('.');
            acc.fileUrls[fileName] = p.fileUrl;
            acc.files[fileName] = p.file;
            return acc;
          }, { fileUrls: {}, files: {} });
        })
      } catch {}
      return { isError: false, data: { ...requestedItem, ...(fileData ?? {}) } };
    }
    const docRef = doc(db, FIREBASE_OBRAS_COLLECTION, obraId);
    const obra = await getDoc(docRef);
    if (!obra) {
      return { isError: true, error: 'Not found' };
    }
    const storageRef = ref(storage, obraId);
    let files, fileData;
    try {
      files = await listAll(storageRef);
      const filePromises = [];
      for (let file of files.items) {
        filePromises.push(new Promise(async (resolve) => {
          Promise.all([getMetadata(file), getDownloadURL(file)])
            .then(([m, url]) => resolve({ file, metadata: m, fileUrl: url }));
        }));
      }
      fileData = await Promise.all(filePromises).then((promises) => {
        return promises.reduce((acc: any, p: any) => {
          const [fileName] = p.metadata.name.split('.');
          acc.fileUrls[fileName] = p.fileUrl;
          acc.files[fileName] = p.file;
          return acc;
        }, { fileUrls: {}, files: {} });
      });
    } catch {}
    return {
      isError: false,
      data: {
        id: obraId,
        ...obra.data(),
        ...(fileData as any)
      },
    };
  };

  const remove = async (obraId = defaultObraId) => {
    if (!obraId) {
      return { isError: true, error: 'No obra Specified' };
    }
    const docRef = doc(db, FIREBASE_OBRAS_COLLECTION, obraId);
    try {
      await deleteDoc(docRef);
      const filesRef = ref(storage, obraId);
      const files = await listAll(filesRef);
      await Promise.all(
        files.items.map((i) => {
          return deleteObject(i);
        })
      );
      cache.clear();
      return { isError: false, data: { deleted: obraId } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const update = async (obra: Obra) => {
    const obraId = obra.id ?? defaultObraId;
    if (!obraId) {
      return { isError: true, error: 'No obra specified' };
    }
    const fileRefs = [];
    await setDoc(doc(db, FIREBASE_OBRAS_COLLECTION, obraId), {
      name: obra.name,
      description: obra.description,
      author: obra.author,
      expositionId: obra.expositionId,
      order: obra.order
    });
    if (obra.files && Object.values(obra.files).length) {
      try {
        for (const file of Object.values(obra.files)) {
          const storageRef = ref(storage, `${obraId}/${file.name}`);
          await uploadBytes(storageRef, file);
          fileRefs.push(storageRef);
        }
      } catch (e) {
        for (const fRef of fileRefs) {
          await deleteObject(fRef);
        }
        return { isError: true, error: e };
      }
    }
    const data = {
      ...obra,
      files: fileRefs,
    };
    cache.clear();
    return { isError: false, data };
  };

  const removeFile = async (fileRef: StorageReference) => {
    try {
      await deleteObject(fileRef);
      cache.clear();
      return { isError: false, data: { deleted: true } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  const list = async (expositionId?: string) => {
    const storedObras = cache.get();
    if (storedObras) {
      return {
        isError: false,
        data: expositionId ? storedObras.filter((o: Obra) => o.expositionId === expositionId) : storedObras,
      };
    }
    let docs = await getDocs(collection(db, FIREBASE_OBRAS_COLLECTION));
    const obras: Obra[] = [];
    const allLoadedPromises = [];
    for (let doc of docs.docs) {
      const storageRef = ref(storage, doc.id);
      const files = await listAll(storageRef);
      const filePromises = [];
      for (let file of files.items) {
        filePromises.push(new Promise(async (resolve) => {
          Promise.all([getMetadata(file), getDownloadURL(file)])
            .then(([m, url]) => resolve({ file, metadata: m, fileUrl: url }));
        }));
      }
      const data = doc.data() as Obra;
      const loadedPromise = Promise.all(filePromises).then((promises) => {
        const fileData = promises.reduce((acc: any, p: any) => {
          const [fileName] = p.metadata.name.split('.');
          acc.fileUrls[fileName] = p.fileUrl;
          acc.files[fileName] = p.file;
          return acc;
        }, { fileUrls: {}, files: {} });
        obras.push({ id: doc.id, ...data, ...(fileData as any) });
      });
      allLoadedPromises.push(loadedPromise);
    }
    await Promise.all(allLoadedPromises);
    cache.set(obras);
    return { isError: false, data: expositionId ? obras.filter((o) => o.expositionId === expositionId) : obras };
  };

  const removeObrasFromExposition = async (expositionId: string) => {
    if (!expositionId) {
      return { isError: true, error: 'No exposition specified' };
    }
    try {
      const q = query(collection(db, FIREBASE_OBRAS_COLLECTION), where('expositionId', '==', expositionId));
      const obras = await getDocs(q);
      const deletePromises = [];
      for (let o of obras.docs) {
        deletePromises.push(remove(o.id));
      }
      await Promise.all(deletePromises);
      cache.clear();
      return { isError: false, data: { deleted: true } };
    } catch (e) {
      return { isError: true, error: e };
    }
  };

  return { list, create, get, remove, update, removeFile, removeObrasFromExposition };
};
