import '../../styles/topExpositions.scss';
import { ExpositionCard } from '../cards/ExpositionCard';
import { useTranslation } from 'react-i18next';
import { useExposition } from '../../hooks/useExposition';
import { useEffect, useState } from 'react';

export const TopExpositions = () => {
  const { t } = useTranslation('global');
  const [topExpositions, setTopExpositions] = useState([]);

  const { listHighlighted } = useExposition();
  const refresh = (forceRefresh = false) =>
    listHighlighted(undefined, forceRefresh).then((r) => {
      if (!r.isError) {
        setTopExpositions(r.data);
      }
    });

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className='top-expositions'>
      <div className='top-expositions-container white-text'>
        <h2 className='white-text'>{t('topExpositions.title')}</h2>
        <div className='mav-grid'>
          {topExpositions.map((t) => (
            <ExpositionCard 
              exposition={t} 
              afterLike={() => refresh(true)} />
          ))}
        </div>
      </div>
    </div>
  );
};
